.modalTitle {
  font-size: 24px;
  line-height: 32px;
  color: #000;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
}

.modalText {
  font-size: 16px;
  line-height: 24px;
  color: #000;

  p {
    margin-bottom: 1rem;
  }
}
