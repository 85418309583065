@import 'src/styles/variables/colors';

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0.9);
  }
}

.rootcontainer {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.header {
  width: 18%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .title {
    font-size: 25px;
    font-weight: bolder;
    line-height: 33px;
    margin-bottom: 11px;
  }

  .description {
    color: #a9a9a9;
  }
}

.cardsContainer {
  width: 82%;
  margin-bottom: 80px;
}

.businessCard {
  border: 1px solid #5777da;
  border-radius: 25px;
  overflow: hidden;
  position: relative;
  height: 480px;
  margin-bottom: 30px;
  & .left {
    color: white;
    background: linear-gradient(to bottom, #5879da 0%, #9bd3dc 100%);
    padding: 25px;
    width: 50%;

    & .popularBadge {
      background-color: rgba(255, 255, 255, 0.2);
      color: white;
      padding: 6px 20px 3px 20px;
      border-radius: 16px;
      font-weight: 500;
      display: inline-block;
      font-size: 12px;
      margin-bottom: 5px;
    }

    & .title {
      font-size: 26px;
      font-weight: bolder;
      margin-left: 3px;
    }

    & .description {
      font-size: 16px;
      margin-bottom: 20px;
      margin-left: 3px;
    }

    & .phonePreview {
      width: 382px;
      height: 308px;
      overflow: hidden;
      position: absolute;
      bottom: 0;
      left: 25%;
      transform: translateX(-50%);

      img {
        width: 382px;
      }
    }
  }

  & .right {
    background-color: #fef8ed;
    padding: 30px;
    width: 50%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & .textContainer {
      display: flex;
      flex-direction: column;
      gap: 15px;

      & .title {
        font-size: 20px;
        font-weight: bolder;
        margin-bottom: 10px;
      }

      & .features {
        display: flex;
        flex-direction: column;
        gap: 15px;
      }

      & .featureTitle {
        font-weight: bold;
      }

      & .featureDescription {
        font-size: 16px;
        color: #3a3a3a;
      }

      & .checkIcon {
        color: #55afb0;
        font-size: 24px;
        flex-shrink: 0;
        margin-top: 3px;
      }
    }

    & .buttonContainer {
      position: relative;

      & img {
        position: absolute;
        top: -100%;
        left: 60%;
        width: 26px;
        height: 52px;
        animation: pulse 2s ease-in-out infinite;
      }

      & .button {
        background-color: #5777da;
        color: white;
        font-size: 16px;
        text-transform: none;
        padding: 15px 20px;

        & svg {
          margin-right: 10px;
          path {
            stroke: white;
          }
        }
      }
    }
  }
}

.personalCard {
  border: 1px solid #e3e3e3;
  border-radius: 25px;
  color: #a9a9a9;

  & .left {
    padding: 25px;
    width: 35%;

    & .title {
      font-size: 26px;
      font-weight: bolder;
      margin-top: 3px;
    }

    & .description {
      color: #5777da;
      font-size: 16px;
      margin-bottom: 20px;
    }

    & .phonePreview {
      width: 382px;
      height: 308px;
      overflow: hidden;
      position: absolute;
      bottom: 0;
      left: 25%;
      transform: translateX(-50%);

      img {
        width: 382px;
      }
    }
  }

  & .right {
    padding: 30px;
    width: 65%;

    & .description {
      margin-bottom: 15px;
    }

    & .button {
      color: #5777da;
      background-color: white;
      font-size: 16px;
      text-transform: none;
      padding: 15px 20px;
      box-shadow: none;
      border: 1px solid #5777da;

      & svg {
        margin-right: 10px;
      }
    }
  }
}
