.root {
  width: 520px;
  margin: 50px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textWrapper {
  text-align: center;
  max-width: 400px;
}

.title {
  margin-top: 6px;
  font-size: 25px;
  line-height: 33px;
}

.description {
  margin-top: 15px;
}

.button {
  margin-top: 130px;
}

.absoluteWrapper {
  position: absolute;
  top: 20px;
  right: 20px;
}
