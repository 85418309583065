@import 'src/styles/variables/colors';

.root {
  position: relative;
}

.authWrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.expandIcon {
  height: 12px;
  margin-left: 12px;
  transform: rotate(-90deg);
  transition: 0.3s all;

  path {
    stroke: #7a7a7a;
  }
}

.expandIconActive {
  transform: rotate(90deg);
}

.image {
  user-select: none;
  margin-right: 13px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.expandWrapper {
  transition: opacity 0.3s, top 0.3s, right 0s 0s;
  opacity: 1;
  position: absolute;
  right: 0;
  z-index: 1;
  top: 40px;
  padding: 15px;
  box-shadow: 0 2px 10px rgba(34 34 34 / 25%);
  border-radius: 8px;
  background-color: #fff;
}

.expandWrapperHidden {
  transition: opacity 0.3s, top 0.3s, right 0s 0.3s;
  top: calc(100% - 20px);
  right: -1000px;
  opacity: 0;
}

.divider {
  border-bottom: 1px solid $border-gray-color;
  margin: 14px 0;
}

.email {
  font-size: 15px;
}

.resync {
  cursor: pointer;
  font-size: 15px;
}
.resync:hover {
  color: $primary-color;
}

.logoOut {
  cursor: pointer;
  font-size: 15px;
  color: $danger-color;
}
