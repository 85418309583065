@import 'src/styles/variables/colors';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.wrapper {
  max-width: 991px;
  text-align: center;
}

.title {
  color: $primary-color;
}

.description {
  margin-top: 30px;
}

.button {
  margin-top: 100px;
}

.absoluteWrapper {
  position: absolute;
  top: 20px;
  right: 20px;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  column-gap: 100px;
  margin-top: 100px;

  button {
    max-width: 280px;
  }
}
