@import 'src/styles/variables/colors';

.root {
  width: 520px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px 10px;
  flex: 1;
}

.contentWrapper {
  text-align: center;
  flex: 1;
}

.title {
  margin-top: 56px;
  font-size: 25px;
  line-height: 33px;
}

.description {
  margin-top: 11px;
}

.trialText {
  margin-top: 11px;
}

.switchWrapper {
  margin-top: 32px;
  display: flex;
  justify-content: center;
  column-gap: 20px;
}

.switchLabel {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #8f8f8f;
  transition: 0.3s all;

  &.selected {
    color: $primary-color;
  }
}

.plans {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
  margin-top: 36px;
}

.singlePlan {
  grid-template-columns: none !important;
  justify-content: center;
}

.planDescription {
  font-size: 15px;
  line-height: 20px;
  color: #5b5b5b;
}

.planCard {
  padding: 26px 28px;
  background: #fff;
  border: 2px solid $border-gray-color;
  box-sizing: border-box;
  border-radius: 6px;
  text-align: left;
  width: 310px;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    box-shadow: 0 0 10px rgba(33 33 33 / 10%);
  }

  &.activePlanCard {
    border-color: $primary-color;
  }
}

.button {
  margin: 30px 0;
}

.absoluteWrapper {
  position: absolute;
  top: 20px;
  right: 20px;
}

.loadingWrapper {
  margin-top: 40px;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 40px;
  width: 100%;
}

.line {
  width: 696px;
  border: 1px solid $border-light-gray-color;
}

.secondaryTitle {
  font-weight: 500;
  margin-top: 30px;
  color: $text-primary;
}

.secondaryDescription {
  font-size: 16px;
  line-height: 21px;
  color: $text-light-gray;
  margin-top: 6px;
}

.calendarList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  row-gap: 16px;
  width: 500px;
  max-height: 150px;
  margin-top: 28px;
  overflow-y: auto;
  padding-bottom: 10px;

  &::-webkit-scrollbar {
    width: 0.25em;
    height: 0.25em;
  }

  &::-webkit-scrollbar-thumb {
    background: #96989b;
    border-radius: 10px;
    opacity: 0.7;
    overflow: auto;
  }
}

.fullWidthList {
  grid-template-columns: 1fr;
}

.calendarItem {
  width: 100%;
  list-style: none;
}

.cancelButton {
  cursor: pointer;
  text-decoration: underline;
  transition: 0.3s all;
  color: $text-light-gray;

  &:hover {
    color: lighten($text-light-gray, 30%);
  }
}
