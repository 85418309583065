@import 'src/styles/variables/colors';

.rootWrapper {
  display: flex;
  flex-direction: column;
}

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  height: 60%;
  width: 100%;
}

.description {
  margin-top: 7px;
}

.controls {
  display: flex;
  flex-direction: column;
  margin: 25px auto 0;
  row-gap: 18px;
}

.buttonWrapper {
  padding: 0 20px;
  position: relative;
}

.skip {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: -15px;
  display: flex;
  align-items: center;
  transform: translateX(100%);
  height: 100%;
}

.skipText {
  font-weight: 500;
  border-bottom: 1px solid #5777da;
  color: $primary-color;
  margin-right: 4px;
}

.secondButton {
  margin-top: 14px;
}

.clickableText {
  font-size: 15px;
  line-height: 18px;
  cursor: pointer;
  margin-top: 10px;
  color: #000;
  transition: all 0.3s;

  &:hover {
    color: lighten(#000, 50%);
  }
}

.title {
  padding: 30px 16px 13px;

  h1 {
    font-size: 20px;
    line-height: 20px;
  }
}

.text {
  padding: 19px 16px 13px;
}

.content {
  position: relative;
  flex: 1;
  padding: 0 12px;
  overflow: auto;
}

.line {
  border-bottom: 1px solid $border-light-gray-color;
  margin: 0 7%;
}

.wrapper {
  padding: 10px 15px;
}

.absoluteWrapper {
  position: absolute;
  top: 20px;
  right: 20px;
}
